/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'fontsource-lato/latin.css';

window.plausible =
  window.plausible ||
  function () {
    (window.plausible.q = window.plausible.q || []).push(arguments);
  };

const queryParams = Object.fromEntries(
  window.location.search
    .substring(1)
    .split('&')
    .map((p) => p.split('='))
    .map(([a, b]) => [a, decodeURIComponent(b)])
);

if (queryParams.example || queryParams.l || queryParams.s || queryParams.q) {
  window.location.replace('https://demo.promlens.com/' + window.location.search + window.location.hash);
}
